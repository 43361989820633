import React from "react";
import moment from "moment/moment";
import { howLongFromNow } from "../../../utils/helper";
import PopupUp from "../../dynamic-page/util/popUp";
import TruncateText from "../../dynamic-page/util/truncateText";

const tableView = (list, handleTableAction) => {
  return {
    list: list ?? [],
    theme: {
      as: (item) => (
        <div className="  ">
          <PopupUp
            toggle={(selected) => (
              <div className=" flex gap-2.5 items-center ">
                <span
                  className={`${
                    item?.status === 1 ? "bg-green-500" : "bg-red-500"
                  } w-[10px] h-[10px] rounded-full `}
                ></span>
                <span className="text-sm text-gray-900 font-medium leading-[21px]">
                  {item?.status === 1 ? "Active" : "On Hold"}
                </span>
              </div>
            )}
            action="hover"
            forcePosition={"top"}
          >
            <div className="flex flex-col gap-1.5 p-1.5 px-2">
              <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                Created at
              </span>
              <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                {moment(item?.created_at).format("MMM D, YYYY h:mm a")}
              </span>
            </div>
          </PopupUp>
        </div>
      ),

      name: (item) => (
        <div className="  ">
          {item?.first_name?.length > 21 ? (
            <PopupUp
              toggle={(selected) => (
                <div className="flex items-center gap-2.5">
                  <div className="w-[32px] h-auto rounded-full">
                    <img
                      alt="none-user"
                      className="w-full h-auto rounded-full"
                      src={item?.image_url || "/assets/images/non-user2.png"}
                    />
                  </div>
                  <span className="text-gray-900 font-semibold text-[14px] leading-[16px]">
                    {item?.first_name?.length > 21 ? (
                      <span>{item?.first_name?.slice(0, 21)}...</span>
                    ) : (
                      <span>{item?.first_name}</span>
                    )}
                  </span>
                </div>
              )}
              action="hover"
              forcePosition={"top"}
            >
              <span className="text-gray-900 font-semibold text-[14px] leading-[16px]">
                {item?.first_name}
              </span>
            </PopupUp>
          ) : (
            <div className="flex items-center gap-2.5">
              <div className="w-[32px] h-auto rounded-full">
                <img
                  alt="non-user"
                  className="w-full h-auto rounded-full"
                  src={item?.image_url || "/assets/images/non-user2.png"}
                />
              </div>
              <span className="text-gray-900 font-semibold text-[14px] leading-[16px]">
                {item?.first_name}
              </span>
            </div>
          )}
        </div>
      ),
      email: (item) => (
        <div>
          <span className="text-gray-500 text-size[14px] leading-[21px] font-normal">
            {item?.email}
          </span>
        </div>
      ),
      "last activity": (item) => {
        let displayTime;

        if (!item?.lastActivity?.datetime) {
          displayTime = "-";
        } else if (
          moment(item?.lastActivity?.datetime).fromNow() === "a few seconds ago"
        ) {
          displayTime = "Just now";
        } else {
          displayTime = howLongFromNow(item?.lastActivity?.datetime, true);
        }
        return (
          <div className="!w-[100%] ">
            <PopupUp
              toggleClassName={"!w-[100%]"}
              toggle={(selected) => (
                <div className="flex  !w-[100%] justify-between">
                  <div className=" ">
                    <span className="text-teal-600 text-[14px] leading-[21px] font-normal ">
                      {displayTime}
                    </span>
                  </div>
                  <div className="text-right !w-[100%]">
                    <TruncateText
                      maxLines={1}
                      hideCopy={true}
                      innerClassName="whitespace-normal"
                      className="text-[14px] text-gray-500 font-normal w-[100%] leading-[21px]"
                    >
                      {item?.lastActivity?.description ?? "-"}
                    </TruncateText>
                  </div>
                </div>
              )}
              action="hover"
              forcePosition={"bottom"}
            >
              <div className="flex flex-col gap-1.5 p-1.5 px-2">
                <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                  {item?.lastActivity?.ip_address || "-"}
                </span>
                <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                  {moment(item?.lastActivity?.datetime).format(
                    "MMM D, YYYY h:mm a"
                  )}{" "}
                  {item?.lastActivity?.created_at?.slice(12, 16)}{" "}
                </span>
              </div>
            </PopupUp>
          </div>
        );
      },

      df: (item) => (
        <div>
          <button
            onClick={() => {
              handleTableAction("activity", item);
            }}
            className="text-blue-700 font-medium text-[14px] leading-[21px]"
          >
            User Activity
          </button>
        </div>
      ),
    },
  };
};
export default tableView;
