import constant from "../../../constant";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import {
  PUPProccessingFeeTooltip,
  PUPProviderTooltip,
  PUPTransferCostTooltip,
} from "..";
import Icons from "../../orders/icons";
import * as Yup from "yup";
const formView = (formDefaultValue) => {
  const MapPin = Icons.mapPin;
  return [
    {
      name: "provider",
      label: "Provider",
      placeholder: "Select the provider",
      list: [
        { label: "Arbitbox", value: "Arbitbox" },
        { label: "Amazon", value: "Amazon" },
        { label: "Walmart", value: "Walmart" },
        { label: "Ozon", value: "Ozon" },
        { label: "ShipEntegra", value: "ShipEntegra" },
      ],
      type: "Select",
      reset: false,
      clear: true,
      defaultValue: formDefaultValue?.provider || null,
      labelIcon: PUPProviderTooltip("Modal"),
      validation: Yup.string().required("Provider is required"),
      className: "col-span-3 ",
      innerClassName: "bg-gray-50 ",
    },
    {
      name: "name",
      label: "Name",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. MENA PUP, Europe point....",
      defaultValue: formDefaultValue?.name || null,
      className: "col-span-3",
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("Name is required"),
    },
    {
      name: "transfer_cost",
      label: "Transfer Cost",
      type: "Number",
      onlyPrice: true,
      handle: true,
      reset: false,
      clear: false,
      placeholder: "ex. $7 per kg",
      defaultValue: formDefaultValue?.transfer_cost || null,
      className: "col-span-3",
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: "US$ per kg",
      labelIcon: PUPTransferCostTooltip("Modal"),
      validation: Yup.string().required("Transfer Cost is required"),
    },
    {
      name: "processing_fee",
      label: "Prc. Fee",
      type: "Number",
      handle: true,
      onlyPrice: true,
      reset: false,
      clear: false,
      placeholder: "ex. $1.5 per item",
      defaultValue: formDefaultValue?.processing_fee || null,
      className: "col-span-3",
      innerClassName: "border border-gray-300 bg-gray-50",
      optional: true,
      labelDiscription: "US$ per item",
      labelIcon: PUPProccessingFeeTooltip("Modal"),
    },
    {
      name: "street1",
      label: "Street 1",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. 8 The Green",
      defaultValue: formDefaultValue?.street1 || null,
      className: "col-span-6",
      icon: <MapPin className="text-gray-500" />,
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("Street 1 is required"),
    },
    {
      name: "street2",
      label: "Street 2",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. Ste A",
      defaultValue: formDefaultValue?.street2 || null,
      className: "col-span-6 ",
      optional: true,
      icon: <MapPin className="text-gray-500" />,
      innerClassName: "border border-gray-300 bg-gray-50",
    },
    {
      name: "country_id",
      label: "Country",
      placeholder: "Select the country",
      type: "Select",
      reset: false,
      clear: true,
      api: {
        url: constant.APIURL.GET_COUNTRIES,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
          flag: item.flag,
        }),
      },
      features: {
        formatOptionLabel: ({ label, flag }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] object-cover  overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img alt="flag" src={flag?.toLowerCase()} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: formDefaultValue?.country?.id || null,
      validation: Yup.string().required("Country is required"),
      className: "col-span-4 ",
      innerClassName: "bg-gray-50",
    },
    {
      name: "state_id",
      label: "State",
      placeholder: "Select the state",
      type: "Select",
      reset: false,
      clear: true,
      features: {
        formatOptionLabel: (child) => (
          <div className={"flex flex-row items-center gap-[8px]"}>
            <span className={"text-gray-800"}>{child?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: "/api/v1/state?filter[country_id]={country_id}",
        query: "&term={query}",
        view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: formDefaultValue?.state?.id || null,
      className: "col-span-4",
      innerClassName: " bg-gray-50",
      validation: Yup.string().required("State is required"),
    },
    {
      name: "city",
      label: "City",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. Dover",
      defaultValue: formDefaultValue?.city || null,
      className: "col-span-4",
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("City is required"),
    },
    {
      name: "postal_code",
      label: "ZIP / Postal Code",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. 19901",
      defaultValue: formDefaultValue?.postal_code || null,
      className: "col-span-4",
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("Zip / Postal Code is required"),
    },
    {
      name: "phone",
      label: "Phone",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. + (1) 234 567 89 00",
      defaultValue: formDefaultValue?.phone || null,
      className: "col-span-4",
      //   validation: Yup.string().required('Phone is required'),
      optional: true,
      innerClassName: "border border-gray-300 bg-gray-50",
    },
    {
      name: "email",
      label: "Email",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. info@arbitbox.com",
      defaultValue: formDefaultValue?.email || null,
      className: "col-span-4  ",
      innerClassName: "border border-gray-300 bg-gray-50",
      optional: true,
      //   validation: Yup.string().email('Invalid email').required('Email is required'),
    },

    {
      name: "note",
      label: "Note",
      type: "Input",
      reset: false,
      clear: false,
      placeholder: "ex. Reservation required or only hazmat accepting...",
      defaultValue: formDefaultValue?.note || null,
      className: "col-span-12",
      optional: true,
      innerClassName: "border border-gray-300 bg-gray-50",
    },
  ];
};
export default formView;
