// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./models-view/table";
import sortView from "./models-view/sort";
import actionsView from "./models-view/actions";
import addForm from "./models-view/addForm";
import optionsView from "./models-view/options";
import PermissionsModal from "./models-view/user-permissions-modal";
import FiltersView from "./models-view/filters";
import UserActivity from "./models-view/userActivity";

// Main Component
const InventoryAdjustmants = () => {
  // Initial Page
  const Page = usePage({
    pageTitle: "Users",
    api: {
      fetch: "users",
      filters: "users/filters",
    },
    ModelsView: {
      table: tableView,
      filter: FiltersView,
      sort: sortView,
      actions: actionsView,
      headerOption: optionsView,
      forms: {
        add: addForm,
        permissions: PermissionsModal,
        userActivity: UserActivity,
      },
    },
    pageSettings: {
      createAnother: true,
      headerStyles: {
        className: "flex justify-start !px-4 !py-4 !h-[50px]",
        as: {
          className: "!hidden",
        },
        df: {
          className: "!hidden",
        },

        user: {
          th: " ",
        },
        "last activity": {
          className: "!text-center !flex !justify-center  ",
        },
        email: {
          className: "",
        },
      },
      rowStyles: {
        td: " !h-[54px] ",
        className: "!px-4 !py-0 text-[14px] flex    !w-max ",
        "last activity": {
          td: "!w-[100%]   ",
          className: "!min-w-[300px] !w-[100%]",
        },
        name: {
          className: "!py-0 !pr-[28px]",
        },
        email: {
          className: "!py-0 !pr-[38px]",
        },
        as: {
          className: "!px-[32px] !pr-[44px]",
        },

        _actions: {
          className: "!px-0 !py-0 ",
        },
      },
    },
  });

  // return View
  return Page;
};

export default InventoryAdjustmants;
